<template>

  <div class="row">
    <div class="col-12 card">
      <h4>Простои на заводе</h4>
      <router-link to="/analytics"><h5><span class="icon-ArrowLeft"></span> Назад к Отчётам</h5></router-link>
      <div class="row justify-content-space-between">
        
        <div class="col-12">
          <strong class="col-5 card-title">Выбрать дату</strong>
          <date-picker class="btn-tab btn-tab-active right-space-16" 
            v-model:value="datefilter" 
            range 
            type="datetime" 
            :show-time-panel="showTimeRangePanel" 
            @close="handleRangeClose">
            <template #footer>
              <button class="mx-btn mx-btn-text" @click="toggleTimeRangePanel">
            {{ showTimeRangePanel ? 'select date' : 'select time' }}
          </button>
        </template>
          </date-picker>
          <strong class="col-5 card-title">Год:</strong>
          <button class="btn-tab right-space-16" @click="setYear(['Sun Jan 01 2024 00:00:00', 'Sun Dec 31 2024 00:00:00'])">2024</button>
        </div>
      </div>

      <strong class="col-5 card-title" v-if="this.datefilter[0]">Период c {{ format_date(this.datefilter[0]) }} по {{ format_date(this.datefilter[1]) }}</strong>
      <div class="row">

          <div class="col-9">
          <h3>Общая продолжительность</h3>
          <table class="col-12">
            <thead>
              <tr>
                <th class="width-25">Простой</th>
                <th class="width-25">Время простоя</th>
              </tr>
            </thead>
            <tbody>
              <tr >
                <td>Всего за период с {{ format_date(this.datefilter[0]) }} по {{ format_date(this.datefilter[1]) }}</td>
                <td>{{ totalStopPeriod }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="row">

      <div class="col-9">
        <h3>Простои</h3>
        <table class="col-12">
          <thead>
            <tr>
              <th class="width-25">Номер документа</th>
              <th class="width-25">Начало</th>
              <th class="width-25">Окончание</th>
              <th class="width-25">Время простоя</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="report in resultQuery">
              <td>{{ report.id }}</td>
              <td>{{ format_date(report.created_at) }}</td>
              <td>{{ format_date(report.finished_at) }}</td>
              <td>{{ report.stop_period }}</td>
            </tr>
          </tbody>
        </table>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';
import moment from 'moment'

export default {
  data() {
    return {
      title: "Справочники",
      datefilter: [this.today_date(), this.tomorrow_date()],
      year: '',
      search: null,
      showTimeRangePanel: false,
    }
  },
  components: { DatePicker },
  computed: {
    StopReports () {
      return this.$store.state.stop_reports.filter((report) => report.status == true)
    },

    resultQuery() {
      this.countArray = []
      const byDate = (item) => {
        const itemDate = new Date(item.created_at);
        return (
          itemDate >= new Date(this.datefilter[0]) &&
          itemDate <= new Date(this.datefilter[1])
        );
      };

      const byTitle = (item) =>
        item.title.toLowerCase().includes(this.search.toLowerCase());

      let results = this.StopReports;

      if (this.search) {
        results = results.filter(byTitle);
      }

      const hasDateFilter =
        this.datefilter?.length >= 2 &&
        this.datefilter[0] &&
        this.datefilter[1];

      if (hasDateFilter) {
        results = results.filter(byDate);
      }
      // this.updateCategories()
      // this.createCountArray(results)

      return results;
    },

    totalStopPeriod() {
      let stop_period_array = this.resultQuery.map(stop => stop.stop_period)
      let time_array = []

      stop_period_array.forEach(function(item) {
         item = item.split(':');
         let hours = parseInt(item[0]) * 60
         let minutes = parseInt(item[1])
         let full_period = parseInt(hours) + parseInt(minutes)
         time_array.push(parseInt(full_period))

        //  console.log(full_period)
      });

      let minutes = time_array.reduce(function(time_array, num){ return time_array + num }, 0)

      var m = minutes % 60;

      var h = (minutes-m)/60;

      var HHMM = (h < 10 ? "0" : "") + h.toString() + ":" + (m < 10 ? "0" : "") + m.toString();
      
      return HHMM
    },

  },

  mounted() {
    this.$store.dispatch("fetchStopReport");
  },
  methods: {
    toggleTimeRangePanel() {
      this.showTimeRangePanel = !this.showTimeRangePanel;
    },
    handleRangeClose() {
      this.showTimeRangePanel = false;
    },
    setYear(year){
      new Date(this.datefilter[0] = year[0])
      new Date(this.datefilter[1] = year[1])
      this.year = year
      return
    },
    format_date(value){
      if (value) {
        return moment(String(value)).format('DD.MM.YYYY HH:mm')
      }
    },
    today_date(){
      const today = new Date();
      const date = today.getFullYear()+'-0'+(today.getMonth()+1)+'-'+today.getDate();
      return date
    },
    tomorrow_date(){
      const today = new Date();
      const date = today.getFullYear()+'-0'+(today.getMonth()+1)+'-'+(today.getDate()+1);
      return date
    },
  }
};
</script>
